import React from 'react';
import './Team.css';
import YoussefImg from '../assets/YC-headshot.jpg';
import JakeImg from '../assets/JB-headshot.jpg';
import SamImg from '../assets/SV-headshot.jpeg';
import RJImg from '../assets/RM-headshot.jpg';
import LexImg from '../assets/LB-headshot.png';
import AlejandroImg from '../assets/AE-headshot.jpg';

const teamMembers = [
  { name: 'Youssef Cherrat', img: YoussefImg },
  { name: 'Jake Beyer', img: JakeImg },
  { name: 'Sam Veliveli', img: SamImg },
  { name: 'RJ May', img: RJImg },
  { name: 'Lex Beyer', img: LexImg },
  { name: 'Alejandro Erazo', img: AlejandroImg },
];

const Team = () => {
  return (
    <section id="team" className="team">
      <h1 className="team-title">Team</h1>
      <div className="team-container">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.img} alt={member.name} className="team-img" />
            <p>{member.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Team;
