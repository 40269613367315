import React, { useState } from 'react';
import './ContactUs.css';
import emailjs from 'emailjs-com';

const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_email: email,
      message: message,
    };

    emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', templateParams, 'YOUR_USER_ID')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        // Optionally clear the form
        setEmail('');
        setMessage('');
        alert('Message sent successfully!');
      }, (err) => {
        console.log('FAILED...', err);
        alert('Failed to send message. Please try again.');
      });
  };

  return (
    <section id="contact-us" className="contact-us">
      <h1 className="contact-title">Contact Us</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <label className="contact-label">
          Enter email address:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="contact-input"
          />
        </label>
        <label className="contact-label">
          Have a question? Suggestion? Concern? Type it in here along with your email address at the top and our team will get back to you!
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            className="contact-textarea"
          />
        </label>
        <button type="submit" className="contact-button">Send</button>
      </form>
    </section>
  );
};

export default ContactUs;
