import React from 'react';
import './Merchandise.css';

const Merchandise = () => {
  return (
    <section id="merchandise" className="merchandise">
      <h1 className="merchandise-title">Merchandise</h1>
      <p className="merchandise-subtitle">Gear meant for true Wahoo Fans!</p>
      <div className="merchandise-container">
        <div className="merch-item">Item 1</div>
        <div className="merch-item">Item 2</div>
        <div className="merch-item">Item 3</div>
      </div>
      <button className="merchandise-button" onClick={() => window.location.href = 'your-store-link'}>
        Hoos Reporting Store
      </button>
    </section>
  );
};

export default Merchandise;
