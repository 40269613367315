import React from 'react';
import './Header.css';
import logo from '../assets/Hoos-Reporting-logo.png';
import { Navbar, Nav } from 'react-bootstrap';

const Header = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="header fixed-top">
      <Navbar.Brand href="#home" className="logo">
        <img src={logo} alt="Hoos Reporting Logo" className="logo-img" />
        <div className="text">
          <h1>HOOS REPORTING</h1>
          <h2>A COLLEGE REPORTING AFFILIATE</h2>
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="nav-right">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#weekly-report">Weekly Report</Nav.Link>
          <Nav.Link href="#merchandise">Merchandise</Nav.Link>
          <Nav.Link href="#about-us">About Us</Nav.Link>
          <Nav.Link href="#contact-us">Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
