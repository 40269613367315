import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <section id="about-us" className="about-us">
      <h1 className="about-title">About Us</h1>
      <p className="about-description">
        Our mission is to make it easier than easy to find essential information about any University. 
        Whether you are an anxious student, concerned parent, or curious alumni, we got you. 
        Speak with our chat-bot to answer ANY of your questions about college.
      </p>
    </section>
  );
};

export default AboutUs;
