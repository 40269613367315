import React from 'react';
import './WeeklyReport.css';

const WeeklyReport = () => {
  return (
    <section id="weekly-report" className="weekly-report">
      <h1 className="weekly-title">The Weekly Report</h1>
      <div className="video-container">
        <iframe 
          width="560" 
          height="315" 
          src="https://youtube.com/embed/hLNGUhWnsGM" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen>
        </iframe>
      </div>
      <p className="weekly-description">
        Join us every week for a new installment of the weekly report, covering all the latest going on at the University of Virginia
      </p>
    </section>
  );
};

export default WeeklyReport;
