import React from 'react';
import './Home.css';
import banner from '../assets/uva-banner.png';
import logo from '../assets/College-Reporting-logo.png';

const Home = () => {
  return (
    <section id="home" className="home">
      <img src={banner} alt="UVA Banner" className="banner" />
      <h1 className="heading">All your College knowledge in one place</h1>
      <div className="button-container">
        <a href="/another-page" className="redirect-button">
          <img src={logo} alt="College Reporting Logo" className="button-image" />
        </a>
      </div>
      <p className="chat-text">Talk to Chatty (Our Chat Bot) with any questions you have about UVA</p>
    </section>
  );
};

export default Home;
